<template>
	<div>
		<v-data-table
			:headers="requestHeaders"
			:items="requestList"
			:items-per-page="15"
			:loading="isLoading"
			@click:row="handleClick"
			:item-class="itemRowBackground"
			class="elevation-1 request_list">
		</v-data-table>
	</div>
</template>

<script>
	export default {
        mounted() {
            this.getData();
        },
		data: () => ({
			isLoading: true,
			requestHeaders: [],
			requestList: []
		}),
		methods: {
			handleClick(item) {
				this.$router.push({ path: '/school/request/'+item.id });
			},
			itemRowBackground: function (item) {
				let rowCLass = '';
				if(item.desicion_id == '2') {
					rowCLass = 'light-green lighten-4';
				} else if(item.desicion_id == '3') {
					rowCLass = 'red lighten-5';
				}
				return rowCLass;
			}, 
			async getData() {
				await this.$http.get('/request/all?currPage=1').then((response) => {
					this.requestHeaders = response.data.data.requestList.headers;
					this.requestList = response.data.data.requestList.list;
					this.isLoading = false;
				});
			}
			
		}
  }
</script>

<style scoped>
	/*.request_list tr {
		cursor: pointer !important;
	}*/
</style>